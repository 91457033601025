var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"8"}},[_c('div',[_c('validation-observer',{ref:"newResourceForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.onCreateOrUpdateResource.apply(null, arguments)}}},[_c('div',{staticClass:"my-1"},[_c('b-form-group',{attrs:{"label":"Title","label-for":"title","label-cols-sm":"4","label-cols-lg":"4","content-col-sm":"","content-cols-lg-7":""}},[_c('validation-provider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"title","placeholder":"Enter resource title","state":errors.length > 0 ? false : null},model:{value:(_vm.resource.title),callback:function ($$v) {_vm.$set(_vm.resource, "title", $$v)},expression:"resource.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),(!_vm.isUpdate)?_c('div',{staticClass:"my-1"},[_c('b-form-group',{attrs:{"label":"Resource Name","label-for":"resource_name","label-cols-sm":"4","label-cols-lg":"4","content-col-sm":"","content-cols-lg-7":""}},[_c('validation-provider',{attrs:{"name":"resource_name","vid":"resource_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"Resource Name","readonly":_vm.isUpdate,"placeholder":"Enter name of resource","state":errors.length > 0 ? false : null},model:{value:(_vm.resource.resource_name),callback:function ($$v) {_vm.$set(_vm.resource, "resource_name", $$v)},expression:"resource.resource_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e(),(!_vm.isUpdate && !_vm.resource.is_a_service)?_c('div',{staticClass:"my-1"},[_c('b-form-group',{attrs:{"label":"Collection Name","label-for":"collection_name","label-cols-sm":"4","label-cols-lg":"4","content-col-sm":"","content-cols-lg-7":""}},[_c('validation-provider',{attrs:{"name":"collection_name","vid":"collection_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"Collection Name","readonly":_vm.isUpdate,"placeholder":"Enter collection name for appropriate resource","state":errors.length > 0 ? false : null},model:{value:(_vm.resource.collection_name),callback:function ($$v) {_vm.$set(_vm.resource, "collection_name", $$v)},expression:"resource.collection_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e(),_c('div',{staticClass:"my-1"},[_c('b-form-group',{attrs:{"label":"Description","label-for":"description","label-cols-sm":"4","label-cols-lg":"4","content-col-sm":"","content-cols-lg-7":""}},[_c('validation-provider',{attrs:{"name":"description","vid":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"Description","placeholder":"Provide a brief description for the resource","state":errors.length > 0 ? false : null},model:{value:(_vm.resource.description),callback:function ($$v) {_vm.$set(_vm.resource, "description", $$v)},expression:"resource.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"my-1"},[_c('b-form-group',{attrs:{"label-cols-sm":"4","label-cols-lg":"4","content-col-sm":"","content-cols-lg-7":""}},[_c('validation-provider',{attrs:{"name":"Service Resource","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"id":"service_resource","disabled":_vm.isUpdate,"name":"service_resource"},model:{value:(_vm.resource.is_a_service),callback:function ($$v) {_vm.$set(_vm.resource, "is_a_service", $$v)},expression:"resource.is_a_service"}},[_c('p',[_vm._v("Service Resource")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"my-1"},[_c('b-form-group',{attrs:{"label-cols-sm":"4","label-cols-lg":"4","content-col-sm":"","content-cols-lg-7":""}},[_c('validation-provider',{attrs:{"name":"Active","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"id":"is_active","name":"is_active"},model:{value:(_vm.resource.is_active),callback:function ($$v) {_vm.$set(_vm.resource, "is_active", $$v)},expression:"resource.is_active"}},[_c('p',[_vm._v("Is active")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),(!_vm.isUpdate)?_c('div',{staticClass:"mt-3"},[_c('h5',[_vm._v(" Default Resource Actions ")])]):_vm._e(),(!_vm.isUpdate)?_c('div',{staticClass:"my-1"},[_c('b-form-group',{attrs:{"label-cols-sm":"4","label-cols-lg":"4","content-col-sm":"","content-cols-lg-7":""}},[_c('validation-provider',{attrs:{"name":"Default Actions","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"id":"default_actions","name":"default_actions"},model:{value:(_vm.include_default_actions),callback:function ($$v) {_vm.include_default_actions=$$v},expression:"include_default_actions"}},[_c('p',[_vm._v("Include Default Actions")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e(),(_vm.include_default_actions)?_c('div',_vm._l((Object.entries(_vm.default_actions)),function(ref,i){
var key = ref[0];
return _c('div',{key:i},[_c('div',{staticClass:"my-1"},[_c('b-form-group',{attrs:{"label-cols-sm":"4","label-cols-lg":"4","content-col-sm":"","content-cols-lg-7":""}},[_c('validation-provider',{attrs:{"name":key,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"id":key,"name":key},model:{value:(_vm.default_actions[key]),callback:function ($$v) {_vm.$set(_vm.default_actions, key, $$v)},expression:"default_actions[key]"}},[_c('p',[_vm._v(_vm._s(key))])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)])}),0):_vm._e(),_c('div',[_c('b-button',{attrs:{"type":"submit","variant":"primary","disabled":invalid}},[_vm._v(" "+_vm._s(_vm.isUpdate ? 'Update Resource' : 'Create Resource')+" ")])],1)])]}}])})],1)])],1)],1),(_vm.isUpdate)?_c('div',[_c('div',[_c('div',{staticClass:"d-flex justify-content-end align-items-center"},[_c('div',{staticClass:"pb-1 pt-2"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.toggleModal}},[_vm._v(" Add Resource Action ")])],1)])]),_c('b-card',[_c('div',{staticClass:"card-body"},[_c('div',[_c('list-table',{attrs:{"records":_vm.resource_actions,"columns":_vm.tableColumns,"current-page-number":_vm.currentPage,"total-records":_vm.totalRecords,"search-filter":_vm.searchFilter},on:{"update:currentPageNumber":function($event){_vm.currentPage=$event},"update:current-page-number":function($event){_vm.currentPage=$event},"update:searchFilter":function($event){_vm.searchFilter=$event},"update:search-filter":function($event){_vm.searchFilter=$event},"row-clicked":_vm.onItemClicked},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(data){return [_c('b-dropdown',{attrs:{"variant":"link","no-caret":"","right":_vm.$store.state.appConfig.isRTL},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"align-middle text-body",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{attrs:{"to":{
                    name: 'admin-update-resource-action',
                    params: {
                      id: data.item._id,
                      resource_id: _vm.getValueFromSource(_vm.resource, '_id')
                    },
                  }}},[_c('feather-icon',{attrs:{"icon":"FileTextIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Update")])],1),_c('b-dropdown-item',{on:{"click":function($event){return _vm.deleteResourceAction(data.item._id)}}},[_c('feather-icon',{staticClass:"text-danger",attrs:{"icon":"TrashIcon"}}),_c('span',{staticClass:"align-middle ml-50 text-danger"},[_vm._v("Delete")])],1)],1)]}}],null,false,3407842149)})],1)])])],1):_vm._e(),_c('div',[_c('b-modal',{ref:"add-resource-action",attrs:{"size":"lg","hide-footer":"","centered":"","hide-header-close":""}},[_c('div',[_c('div',{staticClass:"mb-2"},[_c('h2',[_vm._v("Add Resource Action for Resource ")])])]),_c('div',[_c('add-update-resource-action',{ref:"resourceActionForm",attrs:{"resource-id":_vm.getValueFromSource(_vm.resource, '_id')},on:{"close-modal":_vm.onCloseModal,"create":_vm.onNewResourceAction}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }