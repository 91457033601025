<template>
  <section>
    <div>
      <div class="card-body">
        <div>
          <div>
            <p>Resource</p>
          </div>
          <div>
            <h2>
              {{ getValueFromSource(resource, 'resource_name') }}
            </h2>
          </div>
        </div>

        <div class="mt-3">
          <b-card>
            <div class="py-2">
              <b-row>
                <b-col cols="12">
                  <validation-observer ref="resourceActionForm" #default="{invalid}">
                    <b-form ref="form" @submit.prevent="onNewResourceAction">

                      <!-- resource action name-->
                      <b-form-group
                          label="Resource Action Name"
                          label-for="name"
                          label-cols-sm="4" label-cols-lg="4" content-col-sm content-cols-lg-7
                      >
                        <validation-provider
                            #default="{ errors }"
                            name="name"
                            rules="required"
                        >
                          <b-form-input
                              id="name"
                              v-model="resourceAction.name"
                              :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>


                      <!--access level-->
                      <b-form-group
                          label="Access Level"
                          label-for="access_level"
                          label-cols-sm="4" label-cols-lg="4" content-col-sm content-cols-lg-7
                      >
                        <validation-provider
                            #default="{ errors }"
                            name="access_level"
                            rules="required"
                        >
                          <v-select
                              v-model="resourceAction.access_level"
                              :disabled="loading"
                              :options="access_levels"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>

                      </b-form-group>


                      <div class=" mt-4">
                        <b-button
                            variant="primary"
                            type="submit"
                            :disabled="invalid"
                            @click="closeModalAfterUpdate(false)"
                        >
                          {{ isUpdate ? 'Update Resource Action' : 'Save and Add New' }}
                        </b-button>
                        <b-button
                            v-if="!isUpdate"
                            class="ml-2"
                            variant="primary"
                            type="submit"
                            :disabled="invalid"
                            @click="closeModalAfterUpdate(true)"
                        >
                          Save and Exit
                        </b-button>
                      </div>
                    </b-form>
                  </validation-observer>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required } from "@core/utils/validations/validations";
import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BRow
} from "bootstrap-vue";
import { get } from "lodash";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import vSelect from "vue-select";

const rawResourceAction = {
  resource_id: "",
  name: "",
  access_level: "",
}

const watchUpdateHandler = {
  handler(update) {
    if (update) {
      this.resourceAction = get(update, 'data');
    }
  },
  deep: true,
  immediate: true
}

export default {
  name: "AddUpdateResourceAction",
  components: {
    ValidationProvider,
    ValidationObserver,
    BCol,
    BRow,
    BCard,
    BForm,
    vSelect,
    BButton,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BFormCheckbox,
    BFormCheckboxGroup,
  },
  props: {
    updatingResourceAction: {
      type: [Object, null, undefined],
      default: null,
      required: false
    },
    resourceId: {
      type: String,
      default: null,
      required: true
    }
  },
  data(){
    return {
      loading: false,
      resources: [],
      resource: null,
      closeModal: true,
      resourceAction: { ...rawResourceAction },
      access_levels: ['List', 'View', 'Write', 'Delete'],
      required
    }
  },
  computed: {
    isUpdate(){
      return !!this.updatingResourceAction;
    },
  },
  watch: {
    updatingResourceAction: watchUpdateHandler
  },
  created() {
    this.getSelectedResource();
  },
  methods: {
    closeModalAfterUpdate(state) {
      this.$emit("close-modal", { state })
    },
    async getSelectedResource() {
      try {
        this.loading = true;



        const response = await this.useJwt()
            .adminService
            .getResource(this.resourceId);

        this.resource = response.data.data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async onNewResourceAction() {
      try {
        this.loading = true;

        const success = await this.$refs.resourceActionForm.validate();
        if (!success){
          return;
        }

        const payload = {
          name: this.resourceAction.name,
          access_level: this.resourceAction.access_level,
        }

        if (!this.isUpdate) {
          payload.resource_id = this.resourceId;
        }
        if (this.isUpdate){
          this.$emit("update", payload);
        } else {
          this.$emit("create", payload);
        }
        this.$refs.form.reset();
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    }
  }

}
</script>

<style scoped>

</style>
