<template>
  <b-overlay :show="loading">
    <add-update-resource
      ref="newResourceForm"
      :updating-resource="resource"
      @update="onUpdateResource"
    />
  </b-overlay>
</template>

<script>
import { get } from "lodash";
import { BOverlay } from "bootstrap-vue";
import AddUpdateResource from "@/pages/administrations/resources/add-update-resource.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "UpdateResource",
  components: {
    AddUpdateResource,
    BOverlay
  },
  data() {
    return {
      loading: true,
      resource: null,
      resource_id: "",
    }
  },
  created() {
    this.getResource();
  },
  methods: {
    async getResource() {
      try {
        this.loading = true;

        const { id } = this.$route.params;

        this.resource_id = id;
        const { data } = await this.useJwt()
            .adminService
            .getResource(id);

        this.resource = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async onUpdateResource(payload) {
      try {
        this.loading = false;
        await this.useJwt()
            .adminService
            .updateResource(this.resource_id, payload);

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: `Permission resource updated successfully`
          },
        });
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>

<style scoped>

</style>
